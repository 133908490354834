import { is, isShapeOf, isString, type TypePredicate } from 'giltig';

import type { PermissionGroupName } from '@studio/core-common/utils/permission';
import createEnumValidator from '@studio/utils/createEnumValidator';
import type { EnvironmentType } from '@studio/utils/fromEnvironment';

import type { Role } from '../access';
import type { Avatar } from '../avatars/types';
import type { StudioIdentityUserId } from '../identity';
import type { Inlined, ResourceObject, ToOneRelationship } from '../json-api';

export enum UsersResourceType {
  UserCustomAttributes = 'userCustomAttributes',
}

export enum MfaAction {
  SetupMfa = 'SETUP_MFA',
  VerifyMfa = 'VERIFY_MFA',
}

export type EmailValidationStatus =
  | 'NOT_VALIDATED'
  | 'VALIDATION_EMAIL_SENT'
  | 'VALIDATED';

export type NewsletterPreference =
  | 'NOT_YET_DECIDED'
  | 'SUBSCRIBED'
  | 'UNSUBSCRIBED';

interface AuthProviders {
  username_password?: {
    username: string;
    password?: unknown;
    userId?: string;
    credStore?: string;
    noPassword?: boolean;
    resetPasswordToken?: string;
    resetPasswordTokenTimestamp?: string;
  };
  studio_okta?: {
    userId: string;
  };
}

export type AuthProvider = keyof AuthProviders;

export interface StudioConfig {
  locale: {
    timeZone: string;
  };
}

export interface GetUsersResponse {
  data: FreeTextSearchUser[];
  meta: {
    totalHits: number;
  };
}

export interface GetUsersResponseWithRequestId extends GetUsersResponse {
  requestId?: string | null;
}

export interface UpdateUserResponse {
  userId: string;
  details: Inlined<UserDetails>;
  user: Inlined<UserResource>;
  hasRemovedFromBlockedEmailList: boolean;
}

export type ResetAuthyResponse = Omit<
  UpdateUserResponse,
  'hasRemovedFromBlockedEmailList' | 'details'
>;

export interface Paginated<T> {
  list: readonly T[];
  total?: number | undefined;
  totalPages?: number | undefined;
  totalCount?: number | undefined;
}

export type TokenResourceAttributes = {
  realm: string;
  token: string;
  mfaAction?: MfaAction | null;
  lastLoginTime?: string;
};

export type TokenResource = ResourceObject<'token', TokenResourceAttributes>;

export type UserResource = ResourceObject<
  'user',
  {
    allowedPublishingSites?: readonly string[];
    anonymous: boolean;
    authProviders: readonly AuthProvider[];
    authyId?: string | null;
    bucket: string;
    created: number;
    deleted?: boolean;
    deleteTimestamp?: number;
    updated?: number;
    emailValidationStatus: EmailValidationStatus;
    failedLoginAttempts?: number;
    features: readonly string[];
    firstName: string;
    lastLoginTime: string;
    lastName: string;
    logLevel?: LogLevel;
    mfaEnabled?: boolean;
    newsletterPreference: NewsletterPreference;
    packages: readonly string[];
    products: readonly string[];
    realm: string;
    region: string;
    registeredInLocationTerritory?: string;
    roles?: readonly Role[];
    selectedAuthProvider?: AuthProvider;
    selectedProfileId: string;
    selectedPublishingSite?: string;
    username: string;
    providers?: Partial<AuthProviders>;
    verifiedHomeTerritoriesOverride?: readonly string[];
    countryCodeOverride?: string | null;
    testUser?: boolean;
    testUserDescription?: string | null;
    testUserExpiration?: string | null;
    migratedFromTerritory?: string | null;
    migrationStatus?: 'MIGRATED' | 'MIGRATED_WELCOMED' | null;
  }
>;

export const enum UserType {
  Legacy = 'legacy',
  Modern = 'modern',
}

export interface CurrentUserLegacy {
  readonly type: UserType.Legacy;
  readonly id: string;
  readonly username: string;
  readonly roles: readonly Role[];
  readonly legacyRoles: readonly string[];
  readonly groups: readonly PermissionGroupName[];
  readonly realm: string;
  readonly anonymous: boolean;
  readonly authProvider: AuthProvider | undefined;
  readonly authProviders: readonly AuthProvider[];
  readonly allowedPublishingSites: readonly string[];
  /** @deprecated Read it from RequestContext instead */
  readonly selectedPublishingSite: string | undefined;
}

export interface CurrentUserModern {
  readonly type: UserType.Modern;
  readonly id: StudioIdentityUserId | string;
  /** @deprecated Prefer {@link email} */
  readonly username: string;
  readonly email: string;
  /** Contains roles evaluated from both legacy assigments and groups */
  readonly roles: readonly Role[];
  readonly legacyRoles: readonly string[];
  readonly groups: readonly PermissionGroupName[];
  /** @deprecated */
  readonly realm: string;

  /** @deprecated */
  readonly allowedPublishingSites: readonly string[];
  /** @deprecated Never used in modern mode */
  readonly selectedPublishingSite: string | undefined;
}

export type CurrentUser = CurrentUserLegacy | CurrentUserModern;

export interface FreeTextSearchUser {
  anonymous: boolean;
  countryCodeOverride?: string;
  created: number;
  createdDate: number;
  firstName?: string;
  fullName?: string;
  lastKnownVerifiedHomeTerritory?: string;
  lastLoginTime?: number;
  lastName?: string;
  logLevel?: LogLevel;
  mfaEnabled?: boolean;
  newsletterPreference: NewsletterPreference;
  packages: readonly string[];
  profiles?: Record<string, UserProfileRaw>;
  providers?: readonly any[];
  registeredInLocationTerritory?: string;
  registeredInSite?: string;
  roles?: readonly Role[];
  updated: number;
  updatedDate: number;
  userId: string;
  username?: string;
}

export type RawUser = Omit<FreeTextSearchUser, 'providers'> & {
  providers?: AuthProviders;
};

export enum LogLevel {
  Info = 'INFO',
  Trace = 'TRACE',
  Debug = 'DEBUG',
}

export type UserProfile = ResourceObject<
  'profile',
  {
    age?: number;
    avatarName?: string;
    bandwidthPreference?: string;
    gender?: string;
    profileId?: string;
    profileName?: string;
    birthDay?: number;
    birthMonth?: number;
    birthYear?: number;
    updated?: number;
    isDefault?: boolean;
    isDeletable?: boolean;
    isPreview?: boolean;
    ageRestricted?: boolean;
  },
  {
    avatar?: ToOneRelationship<Avatar>;
  }
>;

export type UserDevice = ResourceObject<
  'device',
  {
    make?: string;
    model?: string;
    createdDate?: string;
    osName?: string;
    osVersion?: string;
    platform?: string;
    updated?: string;
    version?: number;
  }
>;

export type UserDetails = ResourceObject<
  'userdetails',
  {
    detailsId?: string;
    phoneNumber?: string;
    mobileNumber?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    postalCode?: string;
    state?: string;
    country?: string;
  }
>;

export interface UserProfileRaw {
  age?: number;
  ageRestricted?: boolean;
  avatarName?: string;
  bandwidthPreference?: string;
  gender?: string;
  profileName?: string;
  birthDay?: number;
  birthMonth?: number;
  birthYear?: number;
  updated?: number;
}

export enum MfaStatus {
  Pending = 'pending',
  Expired = 'expired',
  Approved = 'approved',
  Denied = 'denied',
}

const isMfaStatus = createEnumValidator(MfaStatus);

export interface MfaStatusResource {
  readonly type: 'mfaStatus';

  readonly attributes: {
    readonly status: MfaStatus;
  };
}

export const isMfaStatusResource: TypePredicate<MfaStatusResource> = isShapeOf({
  type: is('mfaStatus'),
  attributes: isShapeOf({
    status: isMfaStatus,
  }),
});

export interface MfaSetupResource {
  readonly type: 'mfaSetup';
  readonly attributes: {
    readonly qrCode: string;
  };
}

export const isMfaSetupResource: TypePredicate<MfaSetupResource> = isShapeOf({
  type: is('mfaSetup'),
  attributes: isShapeOf({
    qrCode: isString,
  }),
});

export enum MfaVerifyStatus {
  Approved = 'approved',
  InvalidOtp = 'invalidOtp',
  Suspended = 'suspended',
}

export interface verifyMfaCodeResponse {
  readonly status: MfaVerifyStatus;
  readonly retryAfter?: moment.Moment;
}

export enum UmsConfigType {
  UiConfig = 'umsUiConfig',
  MonetizationUiConfig = 'monetizationUiConfig',
}

export type MonetizationUiConfig = ResourceObject<
  UmsConfigType.MonetizationUiConfig,
  {
    acceptedCurrencies: readonly string[];
    availableIapProviders: readonly string[];
    acceptedCustomAttributes?: readonly string[];
    currencyDetails: {
      [currencyCode: string]: {
        currencyDecimalPoints: number;
        displayName: string;
        numericCode: number;
      };
    };
  }
>;

export type UmsUiConfig = ResourceObject<
  UmsConfigType.UiConfig,
  {
    enabledServices: ('legal' | 'monetization')[];
    maxFailedLoginAttempts: number;
    userExportEnabled: boolean;
    /** @deprecated */
    localeConfig: { timeZone: string };
    testUserExpireDaysLimit: number;
    env: EnvironmentType;
  },
  {
    monetizationUiConfig: ToOneRelationship<MonetizationUiConfig>;
  }
>;

export type Partner = ResourceObject<
  'partner',
  {
    name: string;
    partnerUserId?: string;
    gauthUserId?: string;
    logoUrl?: string;
    helpUrl?: string;
    homeUrl?: string;
  }
>;

export type PrivilegedIP = {
  created: string;
  createdBy: string;
  ip: string;
  location: string;
  realm: string;
};

export type GeoOverride = {
  cc: string;
  created: string;
  createdBy: string;
  ip: string;
};

export enum TransactionStatus {
  CREATED = 'CREATED',
  AUTHORIZED = 'AUTHORIZED',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
}

export type BlockedEmail = ResourceObject<
  'blacklist',
  {
    createdTime?: string;
    email?: string;
    reason?: string;
  }
>;

export enum CommunicationHistoryStatus {
  FAIL = 'FAIL',
  SUCCESSFUL = 'SUCCESSFUL',
  UNKNOWN = 'UNKNOWN',
}

export interface CommunicationDataSMS {
  userId: string;
  smsDetails: {
    from: string;
    realm: string;
    templateName?: string;
    templateVersion?: string;
    tokens: {
      AMOUNT: string;
      MASKED_CARD_NUMBER: string;
      NEXT_PAYMENT: string;
      PERIOD: string;
      PRICE_WITH_TAX: string;
      PRODUCT_NAME: string;
      SECONDARY_TITLE: string;
      TAX: string;
      TITLE: string;
      TRANSACTION_CREATED: string;
      TRANSACTION_ID: string;
    };
  };
}

export interface CommunicationDataEmail {
  userId: string;
  eventType: string;
  from: {
    email: string;
    name: string;
  };
  realm: string;
  subject: string;
  templateName: string;
  templateVersion: string;
  timestamp: string;
  to: {
    email: string;
    name: string;
  };
  tokens: {
    EMAIL: string;
    FEEDBACK_FORM: string;
    MAIL_DATE: string;
    NAME: string;
    NAME_REVERSED: string;
    REALM_NAME: string;
    RESET_TOKEN: string;
    RETURN_POLICY: string;
    SERVICE_NAME: string;
    SITE_URL: string;
    STACK_NAME: string;
    TERMS_AND_CONDITIONS: string;
  };
}

export type CommunicationHistory = ResourceObject<
  'communicationHistory',
  {
    provider: string;
    status: CommunicationHistoryStatus;
    userId: string;
    timestamp: string;
  } & (
    | { type: 'EMAIL'; communicationData: CommunicationDataEmail }
    | { type: 'SMS'; communicationData: CommunicationDataSMS }
  )
>;

export type EmailTemplatePreview = ResourceObject<
  'emailTemplatePreview',
  {
    html: string;
    subject: string;
    text: string;
  }
>;

export interface BrazeCampaign {
  name: string;
  api_campaign_id: string;
  last_received: string;
  engaged?: {
    opened_push?: boolean;
    opened_email?: boolean;
    clicked_email?: boolean;
    clicked_triggerd_in_app_message?: boolean;
  };
  in_control?: boolean; // exists only if it is a multivariate campaign
  variation_name?: string; // exists only if it is a multivariate campaign
  variation_api_id?: string; // exists only if it is a multivariate campaign
}

export interface BrazeUser {
  created_at: string;
  user_aliases: readonly {
    alias_name: string;
    alias_label: string;
  }[];
  appboy_id: string;
  braze_id: string;
  random_bucket: number;
  email: string;
  custom_attributes: {
    dtc_id: string;
  };
  total_revenue: number;
  push_subscribe: 'opted_in' | 'subscribed' | 'unsubscribed';
  email_subscribe: 'opted_in' | 'subscribed' | 'unsubscribed';
  campaigns_received: readonly BrazeCampaign[];
}

export interface BrazeResponse {
  users: readonly BrazeUser[];
  message: 'success';
}

export interface Download {
  userId: string;
  profileId: string;
  deviceId: string;
  editId: string;
}

export interface Downloads {
  downloads: Record<string, Download[]>;
  itemDownloaded: number;
}

export interface DefederateUserPayload {
  userId: string;
  gauthUserId: string;
  details: string;
}
