import {
  type match as Match,
  matchPath,
  Router,
  useLocation,
} from 'react-router';

export interface AnyParams {
  [name: string]: string | undefined;
}

export interface RouteDescriptor {
  path?: string | readonly string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
  children?: readonly this[];
}

export interface Branch<Route extends RouteDescriptor = RouteDescriptor> {
  route: Route;
  match: Match<AnyParams>;
}

// Traverse the matching path and its children
export function* matchRoutes<Route extends RouteDescriptor>(
  pathname: string,
  routes: readonly Route[] = [],
  parentMatch?: Match<any>,
): Generator<Branch<Route>> {
  for (const route of routes) {
    const match =
      route.path !== undefined
        ? matchPath(
            pathname,
            // @ts-expect-error - React 18 types are stricter
            route,
          )
        : (parentMatch ??
          // @ts-expect-error - Secret API
          Router.computeRootMatch(pathname));

    if (match !== null) {
      yield { route, match };
      yield* matchRoutes(pathname, route.children, match);

      // Stop after the first match
      break;
    }
  }
}

export function useFirstRoute<Route extends RouteDescriptor>(
  routes: readonly Route[],
): Branch<Route> | undefined {
  const { pathname } = useLocation();
  const [firstRoute] = matchRoutes(pathname, routes);
  return firstRoute;
}

export default function useRoutes<Route extends RouteDescriptor>(
  routes: readonly Route[],
): readonly Branch<Route>[] {
  const { pathname } = useLocation();
  return Array.from(matchRoutes(pathname, routes));
}
