import { type ReactNode, useMemo, useSyncExternalStore } from 'react';
import {
  // @ts-expect-error - this export is not declared
  __HistoryContext as HistoryContext,
  __RouterContext as RouterContext,
  MemoryRouter,
  type RouteComponentProps,
} from 'react-router';

import type { HybridHistory } from './hybridHistory';

export const SonicMemoryRouter = MemoryRouter;

export interface HybridHistoryRouterProps {
  history: HybridHistory;
  children: ReactNode;
}

// An alternative Router implementation using the new useSyncExternalStore, so it works with concurrent features in React 18
export default function HybridHistoryRouter({
  history,
  children,
}: HybridHistoryRouterProps): JSX.Element {
  const location = useSyncExternalStore(history.listen, history.getLocation);

  const contextValue = useMemo<RouteComponentProps>(
    () => ({
      history,
      location,
      match: {
        path: '/',
        url: '/',
        params: {},
        isExact: location.pathname === '/',
      },
      staticContext: undefined,
    }),
    [history, location],
  );

  return (
    <HistoryContext.Provider value={history}>
      <RouterContext.Provider value={contextValue}>
        {children}
      </RouterContext.Provider>
    </HistoryContext.Provider>
  );
}
