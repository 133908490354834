import './bootstrap';
import './i18n';

import { lazy, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { SessionManager } from '@studio/core/components/SessionManager';

import appPromise from './appPromise';

const App = lazy(() => appPromise);

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <SessionManager>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </SessionManager>
  </StrictMode>,
);

if (import.meta.webpackHot !== undefined) {
  import.meta.webpackHot.accept();
  import.meta.webpackHot.dispose(() => {
    root.unmount();
  });
}
